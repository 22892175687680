<template>
    <div>
        <div class="navbox">
            <div class="nav">
                <div class="navitem" @click="toflfg">法律法规</div>
                <div class="navitem navactive">部门规章</div>
                <div class="navitem" @click="togfxwj">规范性文件</div>
                <div class="navitem" @click="tozcjd">政策解读</div>
                <div class="navitem" @click="toptgz" style="padding-right: 0.23rem;">平台规则</div>

                <div class="navxian"></div>
            </div>
        </div>

        <div class="xgyxbox" v-if="articlelist[0]">

            <div class="newsitem" v-for="(item,index) in articlelist" :key="index">
                <router-link :to="{path:`/newsdetail/${item.id}`}">
                    <div class="newsitembox">
                        <div class="newsitemleft">
                            <div class="newstitle">{{item.title}}</div>
                            <div class="newstime">
                                <span>来源:</span>
                                <span v-if="item.infoSource.length <= 6">{{item.infoSource}}</span>
                                <span v-else>{{item.infoSource.slice(0,6)}}...</span>
                                <span>时间:</span><span>{{item.pushTime | dataFilter}}</span>
                            </div>
                        </div>
                        <div class="newsitemright">
                            <img :src="item.widePic">
                        </div>
                    </div>
                </router-link>
            </div>




        </div>

        <div v-else style="height:calc(100vh - 3rem - 0.6rem - 1rem);">

        </div>



    </div>
</template>



<script>
import Cookies from 'js-cookie'
import {getMovieArticle} from "@/api/index"
import { Loading ,Message } from 'element-ui';
export default {
    data(){
        return {
            articlelist:[],
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
            load : null,
        }
    },
    methods:{
        toflfg(){
            this.$router.push({name:'flfg'});
        },
        tobmgz(){
            this.$router.push({name:'bmgz'});
        },
        togfxwj(){
            this.$router.push({name:'gfxwj'});
        },
        tozcjd(){
            this.$router.push({name:'zcjd'});
        },
        toptgz(){
            this.$router.push({name:'ptgz'});
        },
        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            var url = this.$route.path

            if(this.scrollHeight > this.clientHeight && this.t + this.clientHeight + 200  >= this.scrollHeight) {
                if(this.articleload && this.articlelist.length && this.articlelist.length < this.total){
                    if(!this.$route.query.articleId){
                        if(url.indexOf("bmgz") != -1){
                            this.showLoading()
                            this.articleload = false;
                            this.currPageNo += 1;
                            getMovieArticle({
                                "columnId":'1660575066411622401',
                                "pageNo": this.currPageNo,
                                "pageSize": this.pageSize
                            }).then(res => {
                                console.log(res)
                                this.articlelist = this.articlelist.concat(res.result.records)
                                this.total = res.result.total;
                                this.load && this.load.close();
                            })

                            setTimeout(() => {
                                this.articleload = true;
                            },3000)

                        }
                    }
                    
                }
                
            }
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
    },
    mounted(){

        this.showLoading()

        // let head = document.getElementsByTagName('head');
        // let meta = document.createElement('meta');
        // document.querySelector('meta[name="keywords"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // document.querySelector('meta[name="description"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // meta.content = {
        //     keywords:"新闻资讯-影视资讯-湖北引未来影视",
        //     description:"新闻资讯-影视资讯-湖北引未来影视",
        // };
        // head[0].appendChild(meta)
        // document.title = "新闻资讯-影视资讯-湖北引未来影视";


        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        getMovieArticle({
            "columnId":'1660575066411622401',
            "pageNo": this.currPageNo,
            "pageSize": this.pageSize
        }).then(res => {
            console.log(res)
            this.articlelist = res.result.records;
            this.total = res.result.total;
            this.load && this.load.close();
        })


    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },
}
</script>

<style lang="scss" scoped>
.navbox{
    width: 100%;
    height: 0.6rem;
    background: #FFFFFF;
    position: sticky;
    top: 1.08rem;
    z-index: 997;
    overflow-x: scroll;
}
.navbox::-webkit-scrollbar {
    display: none;
}
.nav{
    width: 100%;
    height: 0.6rem;
    display: flex;
    background-color: #fff;
    position: relative;
    z-index: 997;
    border-bottom: 0.02rem solid rgba(112, 112, 112, .2);
}
.navfixed{
    position: fixed;
    top: 1.08rem;
}
.navitem{
    font-size: 0.28rem;
    font-weight: 400;
    line-height: 0.6rem;
    color: #343434;
    white-space: nowrap;
    margin: 0 0.23rem;
    text-align: center;
}
.navactive{
    color: #A30001;
}
.navxian{
    width: 0.46rem;
    height: 0.04rem;
    background: #A30001;
    border-radius: 2.4rem;
    position: absolute;
    bottom: 0rem;
    left: calc(0.28rem * 3 + 1.04rem * 1 + 0.52rem - 0.23rem);
    transition: .4s ease;
}





.xgyxbox{
    width: 100%;
    padding: 0.2rem 0.22rem 0;
    box-sizing: border-box;
}
.xgyxbox .newsitem{
    width: 100%;
    height: 1.84rem;
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
    border-bottom: 0.02rem solid #F4F4F4;
    position: relative;
    .newsitembox{
        height: 1.84rem;
        display: flex;
        justify-content: space-between;
    }
}
.xgyxbox .newsitem .newsitemleft{
    position: relative;
    width: 4rem;
}
.xgyxbox .newsitem .newsitemleft .newstitle{
    width: 3.9rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.4rem;
    color: #343434;
    margin-bottom: 0.1rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 3;    
    overflow: hidden;

}
.xgyxbox .newsitem .newsitemleft .newstime{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.32rem;
    color:#BABABA;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
}
.xgyxbox .newsitem .newsitemleft .newstime span{
    display: inline-block;
    transform: scale(.84);
    transform-origin:0 0; 
}
.newsitemright{
    width: 2.88rem;
    height: 100%;
    overflow: hidden;
    border-radius: 0.08rem;
}
.newsitemright img{
    width: 2.88rem;
    height: 100%;
}
.newsxian{
    width: 7rem;
    height: 0.02rem;
    background-color:#F4F4F4;
    margin: 0 auto 0.2rem;
}

</style>